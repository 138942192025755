@font-face {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: swap
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
  font-family: source-sans-pro, sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

:root {
  --primary: #1D3D71;
  --secondary: #1578BC;
  --white: #FFFFFF;
  --yellow: #F4C000;
  --orange: #E59825;
  --remote-primary: #1D3D71;
  --remote-secondary: #1578BC;
  --remote-tertiary: #1578BC;
  --default-padding: 1em;
}
