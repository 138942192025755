@keyframes modal-open {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.modal {
  /*position: absolute;*/
  /*top: 0;*/
  z-index: 20;
  /*animation: modal-open 0.2s;*/
}

.App {
  color: white;
  background-color: var(--secondary);
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Page {
  display: flex;
  height: 100%;
  flex-direction: column;@import("App.css");
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.05));
}

#map {
  height: 100%;
  width: 100%;
}

header {
  background-color: white;
  color: var(--remote-primary);
  min-height: 55px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.1))
}

section {
  background-color: var(--white);
  color: var(--primary);
  padding: 0 1em 4em 1em;
  line-height: 1.3em;
}

div :hover {
  cursor: pointer;
}

article {
  padding-bottom: calc(2.5 * var(--default-padding));
  white-space: pre-wrap;
}

.heading {
  padding-bottom: 1.5em;
}



footer {
  background-color: var(--white);
  position: relative;
  color: var(--primary);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 47px;
  padding-bottom: env(safe-area-inset-bottom);
}

@media (min-width: 1024px) {
  footer {
    border-radius: 0 0 20px 20px;
  }
}

.tab-bar {
  font-size: 1.6em;
}

footer #selected {
  color: var(--secondary);
  transition: color 0.2s;
}

footer a {
  text-decoration: none;
  color: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#selected-bar {
  bottom: calc(42px + env(safe-area-inset-bottom));
  width: 10%;
  height: 5px;
  background-color: var(--secondary);
  border-radius: 0 0 2px 2px;
  position: absolute;
  margin-left: 2.5%;
  transition: left 0.2s;
  transition-timing-function: ease-in;
  transform: translate(50%, 0)
}

h1 {
  padding: 0.8em 0;
  font-size: 2.8em;
  line-height: 1em;
  font-weight: 800;
}

h2 {
  text-transform: uppercase;
  font-size: 1.2em;
}

h3 {
  font-size: 1.2em;
  line-height: 2em;
}

h4 {
  font-size: 1.2em;
  font-weight: 400;
}

h5 {
  font-size: 1em;
  font-weight: 600;
}

h6 {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.6;
}

p {
  font-size: 1em;
  font-weight: 400;
}

.darken {
  filter: brightness(20%);
}

.more h1 {
  text-transform: capitalize;
}

.plain-text-box {
  padding: 0 1em;
}

.home-explore {
  border: var(--white);
  border-width: 1px;
  border-style: solid none none none;
}

.home-explore-controls {
  width: 60%;
  height: 70px;
  border-style: none solid none none;
  border-width: 1px;
  padding: 0 1em;
}

#view-all {

  padding: 0 1em;
}

#view-all-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 44px;
  border-radius: 22px;
  background-color: #1D3D71;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#title {
  display: flex;
  flex: 1;
  margin: 0.8em 1em;
  padding: 0;
  max-width: 250px;
}

#title h1 {
  padding: inherit;
  padding-bottom: 0.2em;
}

.trail-annotation {
  max-height: 50px;
  border-radius: 25px;
  /*color: #1578BC;*/
  background-color: #FFF;
  opacity: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  flex-direction: row;
  padding: 5px 5px 5px 12px;
}

.trail-annotation svg {
  padding: 0;
  margin: 0;
}

.circle-annotation {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #FFF;
  background-color: #CCC;
  text-align: center;
  line-height: 32px;
}

.hidden-element {
  display: none;
}

.dynamic-image {
  object-fit: cover;
  height: min(calc(150px + 15vh), 300px);
}

.home-img {
  object-fit: cover;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-view {
  height: 100%;
  overflow: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #FFF;
}

.alt-tab {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.alt-tab h5 {
  /* Fixes the centering issue from the svg */
  padding-right: 13px;
}

.center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.back-circle {
  background-color: var(--remote-primary);
  color: #FFF;
  width: 30px;
  height: 30px;
  max-height: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fact-box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 0 1em;
  padding: 1em 0;
  background-color: var(--remote-tertiary);
  position: relative;
  top: -30px;
}

img {
  background-color: #FFF;
}

.fact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fact h6 {
  color: var(--remote-secondary);
}

.fact h5 {
  color: var(--remote-primary);
}

.trail-site {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  border: none;
  background-color: #E59800;
  color: white;
  font-weight: bold;
  text-align: center;
  line-height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

a {
  display: inherit;
  text-decoration: none;
  color: inherit;
  /*background-color: none;*/
}

a :active {
  filter: brightness(120%);
}

.home-explore-controls .circle-annotation {
  color: var(--primary);
  background-color: white;
}

.more {
  height: 100%;
  background-color: var(--primary);
  color: var(--white);
}

.search {
  background-color: var(--secondary);
  color: var(--white);
}

.search .home-img {
  margin-right: 0;
  margin-bottom: 1em;
  height: 100%;
  width: 100%;
  aspect-ratio: 1.4;
}

.more-button {
  padding: 1em 1em;
  border: 0 solid #888888;
  border-bottom-width: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.more-icon {
  padding-right: 16px;
  font-size: 34px;
}

.control-dots {
  display: flex;
  justify-content: flex-end;
}

.scroll-view .carousel .control-dots .dot {
  box-shadow: none;
  /*border: 1px solid #bbb;*/
  background-color: var(--remote-primary);
  opacity: 0.6;
  margin: 0 5px;
}

.scroll-view .carousel .control-dots .dot .selected {
  box-shadow: none;
  opacity: 1;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 var(--default-padding) 5em var(--default-padding);
}

input {
  padding: var(--default-padding);
  margin-bottom: calc(1.2 * var(--default-padding));
  box-sizing: border-box;
  width: 100%;
}

textarea {
  padding: var(--default-padding);
  box-sizing: border-box;
  width: 100%;
  margin-bottom: calc(1.2 * var(--default-padding))
}

label {
  padding: 0 0 var(--default-padding) 0;
}

form button {
  padding: var(--default-padding);
  border-radius: 20px;
  height: 40px;
  width: 300px;
  background-color: var(--secondary);
  border: none;
  color: var(--white);
  margin: 2em auto auto;
}

.site-copy {
  margin-top: 2em;
}

#frame {
  position: absolute;
  left: 0;
  height: 780px;
  background-color: transparent;
  pointer-events: none;
  z-index: 101;
}

#topbar {
  position: absolute;
  background-color: black;
  border-radius: 30px 30px 0 0;
  height: 58px;
  width: 343px;
  left: 30px;
  top: 20px;
}

#device-container {
  height: 780px;
  width: 404px;
  position: relative;
}
.container {
  max-width: 1300px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2em;
  width: 100%;
  height: 100%;
}

#iframe {
  border-radius: 0 0 30px 30px;
  position: absolute;
  height: 695px;
  width: 343px;
  top: 61px;
  left: 31px;
  border: none;
  background-clip: content-box;
}

.desktop-text h1 {
  padding-bottom: 15px;
}

.desktop-text .text-container {
  max-width: 490px;
  margin: var(--default-padding);
}

#device {
  display: block;
}

.desktop-text span {
  color: blue;
  text-decoration: underline;
  padding-left: 4px;
}


#trails {
  display: flex;
  /*flexWrap: "nowrap",*/
  overflow-x: scroll;
  /*overflowY: "hidden",*/
  height: 100%;
}

#homePageCarousel {
  flex: 1.5;
}

.carousel-container {
  background-color: var(--white);
  flex-direction: row;
  background-clip: padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.carousel-label {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 140px;
  min-width: 140px;
}

.carousel-label h3 {
  text-align: center;
  line-height: 1.3;
  padding: min(7%, 80px);
}

#logo {
  height: 55px;
  width: 90px;
  object-fit: contain;
}


.carousel .control-arrow:before {
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.8)) !important;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}
